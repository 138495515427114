import React, { useState, useEffect, useRef } from 'react';
import GooglePayButton from '@google-pay/button-react';
import axios from 'axios';
import { history } from "../../../helpers/history";
import { config } from "../../../enviroment";
import { store } from "../../../helpers/store";
import { routePaths } from '../../config/route-paths';
import { setFingerPrintId } from './fingerPrintId';
import moment from 'moment';

function GooglePayButtonComponent({ state, disabled, nsaClick }) {
  console.log(state)
  const [gPayConfig, setGPayConfig] = useState(null);
  const [configLoading, setConfigLoading] = useState(true);
  const [confirmation, setConfirmation] = useState(null); // State for confirmation message
  const [showConfirmation, setShowConfirmation] = useState(false);
  let demographicDetails = store?.getState()?.accountInformation?.demographicDetails;
  let bearer = localStorage.getItem("bearer"), emailId = "", userName = "", sessionId = "";
  useEffect(() => {
    const fetchGPayConfig = async () => {
      try {
        setConfigLoading(true);
        const response = await axios.get(config.urls.USAGE_SERVICE_URL + '/google-pay/config');
        setGPayConfig(response.data);
        setConfigLoading(false);
      } catch (error) {
        setConfigLoading(false);
        console.error('Error fetching Google Pay config:', error);
      }
    };

    fetchGPayConfig();
  }, []);
  let transactionInfo;
  function fixNames(firstName, lastName) {
    firstName = firstName?.trim();
    lastName = lastName?.trim();
    if (!lastName) {
      const nameParts = firstName.split(' ');
      if (nameParts.length > 1) {
        lastName = nameParts.pop();
        firstName = nameParts.join(' ');
      } else {
        lastName = firstName;
      }
    }

    return { firstName, lastName };
  }
  if (bearer) {
    bearer = JSON.parse(bearer);
    // emailId = bearer?.email;
    userName = bearer?.userName;
    sessionId = bearer?.sessionId;
  }
  let enableButton
  if(state?.source === "QUICK_PAY") {
    enableButton = transactionInfo?.connections?.length > 0 && Number(transactionInfo?.totalPrice) > 0;
    let connections
    if(state?.connectionId && state?.amount) {
      connections = [{ ConnectionId: state?.connectionId, Amount: Number(parseFloat(state?.amount).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2
      })}]
    }
    transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: state?.amount?.toString(),
      // totalPrice: "1.00",
      currencyCode: 'QAR',
      countryCode: 'QA',
      userName: userName,
      sessionId: sessionId,
      connections: connections || [],
      mobile: state?.phoneNum?.replace(/[()\s+]/g, ''),
      email: state?.email,
      city: state?.city,
      firstName: fixNames(state?.firstName, state?.lastName)?.firstName || "",
      lastName: fixNames(state?.firstName, state?.lastName)?.lastName || "",
      premise: state?.premiseAddress?.split(',').slice(0, 3).join(',').trim(),
      source: "QUICK_PAY"
    }
  } else if(state?.source === "SERVICE_PAY") {
    enableButton = Number(state?.amount?.toString()) > 0;
    let connections
    if(state?.connectionId && state?.amount) {
      connections = [{ ConnectionId: state?.connectionId, Amount: Number(parseFloat(state?.amount).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2
      })}]
    }
    transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: state?.amount?.toString(),
      // totalPrice: "1.00",
      currencyCode: 'QAR',
      countryCode: 'QA',
      userName: userName,
      sessionId: sessionId,
      connections: connections || [],
      mobile: state?.phoneNum?.replace(/[()\s+]/g, ''),
      email: state?.email,
      city: state?.city,
      firstName: fixNames(state?.firstName, state?.lastName)?.firstName || "",
      lastName: fixNames(state?.firstName, state?.lastName)?.lastName || "",
      premise: state?.premiseAddress?.split(',').slice(0, 3).join(',').trim(),
      source: "SERVICE_PAY"
    }
  } else {
    return;
  }
  const handleError = (error) => {
    if (error instanceof Error) {
      console.error('JavaScript Error:', error.message, error.stack);
    } else {
      console.error('Google Pay Error:', error.statusCode, error.statusMessage);
    }
  };
  const handleReadyToPayChange = (result) => {
    console.log('Ready to Pay change', result);
  };
  const handlePaymentAuthorized = (paymentData) => {
    console.log('Payment Authorized:', paymentData);
    return { transactionState: 'SUCCESS' };  // Return success state if authorization is good
  };
  const handlePaymentDataChanged = (intermediatePaymentData) => {
    console.log('Payment Data Changed:', intermediatePaymentData);
    return {};
  };
  const handleLoadPaymentData = async (paymentData) => {
    const premiseAddress = [
      paymentData.shippingAddress.address1,
      paymentData.shippingAddress.address2,
      paymentData.shippingAddress.address3,
    ]
    .filter((line) => line)
    .join(", ");
    // transactionInfo = {
    //   ...transactionInfo,
    //   email: paymentData.email,
    //   mobile: paymentData.shippingAddress.phoneNumber,
    //   city: paymentData.shippingAddress.locality,
    //   premise: premiseAddress
    // }
    if(nsaClick) {
      try {
        transactionInfo = {...transactionInfo, orderId: generateOrderId(true)}
        let res = await nsaClick(transactionInfo.orderId)
        console.log(res)
        setFingerPrintId("");
        history.push({
          pathname: routePaths.GOOGLE_PAY_PROCESS,
          state: { paymentData, transactionInfo, gatewayConfig: gPayConfig }
        });
      } catch(error) {

      }
    } else {
      transactionInfo = {...transactionInfo, orderId: generateOrderId()}
      setFingerPrintId("");
      history.push({
        pathname: routePaths.GOOGLE_PAY_PROCESS,
        state: { paymentData, transactionInfo, gatewayConfig: gPayConfig }
      });
    }
  };
  const generateOrderId = (isNsa) => {
    return `GPAY-${isNsa?"NSA-":""}` + transactionInfo.connections[0]['ConnectionId'] + moment(new Date()).format("DDMMYYYYHHmmssSS");
  }
  if (configLoading) {
    return <div>Loading Google Pay...</div>;
  }
  if (!gPayConfig) {
    return <></>
  }
  return (
    <>
      <GooglePayButton
        environment={gPayConfig.environment}  // Use 'PRODUCTION' for live environment
        paymentRequest={{
          apiVersion: gPayConfig.gPayApiVersion,
          apiVersionMinor: gPayConfig.gPayApiVersionMinor,
          allowedPaymentMethods: [{
            type: 'CARD',
            parameters: {
              allowedAuthMethods: gPayConfig.allowedAuthMethods,
              allowedCardNetworks: gPayConfig.allowedCardNetworks,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: gPayConfig.gateway,
                gatewayMerchantId: gPayConfig.gatewayMerchantId,
              },
            },
          }],
          merchantInfo: {
            merchantName: gPayConfig.gPayMerchantName,
            merchantId: gPayConfig.gPayMerchantId,
            // merchantId: '12345678901234567890',
          },
          transactionInfo: {
            totalPriceStatus: transactionInfo.totalPriceStatus,
            totalPrice: transactionInfo.totalPrice,
            currencyCode: transactionInfo.currencyCode,
            countryCode: transactionInfo.countryCode,
          },
          emailRequired: true,
          shippingAddressRequired: true,
          shippingAddressParameters: {
            phoneNumberRequired: true,
            // allowedCountryCodes: ["QA"]
          }
        }}
        onLoadPaymentData={handleLoadPaymentData}  // Triggered when payment data is received
        // onPaymentAuthorized={handlePaymentAuthorized}  // Triggered when payment is authorized
        // onPaymentDataChanged={handlePaymentDataChanged}  // Handle updates to payment data
        onError={handleError}  // Handle Google Pay errors
        buttonType="pay"
        buttonColor="black"
        style={ (disabled || !enableButton) ? {pointerEvents: "none", opacity: "0.5" } : {}}
      />
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-content">
            <p>{confirmation}</p>
            <button onClick={() => setShowConfirmation(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default GooglePayButtonComponent;
